@import "./variables";

.swal2-title {
  font-size: 18px !important;
  font-weight: bold !important;
  margin: 0 !important;
}
.swal2-html-container {
  margin: 0.3em !important;
  font-size: 15px !important;
}
.swal2-confirm {
  background-color: $primary-color !important;
  box-shadow: none !important;
}
