//DEPRECATED, usar las clases de abajo
.le_btn_success {
  background-color: #00c851 !important;
}
.le_btn_warning {
  background-color: #fb3 !important;
}
.le_btn_danger {
  background-color: #ff3547 !important;
}
.le_btn_info {
  background-color: #417cff !important;
  color: white !important;
}
.le_btn_ligth {
  background-color: #e0e0e0 !important;
}
.le_btn_dark {
  background-color: #212121 !important;
}
button:disabled,
button[disabled] {
  border: none !important;
  background-color: #cccccc !important;
  color: #696969 !important;
  cursor: not-allowed;
}

/** Brand **/
.sm-logo {
  @apply text-blue-500 text-lg font-bold;
}
.sm-logo-white {
  @apply text-white text-lg font-bold;
}

/** Forms **/
.sm-form {
  @apply bg-white border-2 border-gray-200 rounded-md w-full text-gray-700 placeholder-gray-700 leading-tight transition ease-in-out text-sm py-2 px-4 h-10
  focus:outline-none focus:bg-white focus:border-blue-700;

  &__disabled {
    @apply bg-gray-100 border-2 border-gray-200 rounded-md w-full text-gray-700 placeholder-gray-700 leading-tight transition ease-in-out text-sm py-2 px-4 h-10 cursor-not-allowed;
  }
}
.sm-textarea {
  @apply bg-white border-2 border-gray-200 rounded-md w-full text-gray-700 placeholder-gray-700 leading-tight transition ease-in-out text-sm py-2 px-4 h-32
  focus:outline-none focus:bg-white focus:border-blue-700;

  &__disabled {
    @apply bg-gray-100 border-2 border-gray-200 rounded-md w-full text-gray-700 placeholder-gray-700 leading-tight transition ease-in-out text-sm py-2 px-4 h-10 cursor-not-allowed;
  }
}
input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched,
select.ng-invalid.ng-touched {
  @apply border-2 border-red-500 text-red-500 placeholder-red-500;
}

/** Buttons **/
.btn {
  @apply rounded-md text-sm py-2 transition ease-in-out outline-none px-4;
}
.btn-primary {
  @extend .btn;
  @apply bg-blue-500 text-white hover:bg-blue-400;

  &__rounded {
    @apply bg-blue-500 text-white hover:bg-blue-400 rounded-full w-10 h-10;
  }
}

.btn-gray {
  @extend .btn;
  @apply bg-gray-200 text-gray-800;

  &__rounded {
    @apply bg-gray-200 text-gray-800 rounded-full w-10 h-10;
  }
}

.btn-green {
  @extend .btn;
  @apply bg-green-500 text-white;

  &__rounded {
    @apply bg-green-500 text-white rounded-full w-10 h-10;
  }
}

.btn-amber {
  @extend .btn;
  @apply bg-amber-500 hover:bg-amber-400 text-white;

  &__rounded {
    @apply bg-amber-500 hover:bg-amber-400 text-white rounded-full w-10 h-10;
  }
}

.btn-red {
  @extend .btn;
  @apply bg-red-500 text-white;

  &__rounded {
    @apply bg-red-500 text-white rounded-full w-10 h-10;
  }
}

.btn-black {
  @extend .btn;
  @apply bg-black text-white;

  &__rounded {
    @apply bg-black text-white rounded-full w-10 h-10;
  }
}
