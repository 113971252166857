/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: #e2e2e2;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #adadad;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #909090;
    cursor: pointer;
}
