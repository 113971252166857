// Mat table
.mat-header-cell {
  color: #191818 !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
}
.mat-row {
  gap: 10px;
  padding: 7px 0 7px 0 !important;
}
.mat-paginator-icon {
  cursor: pointer !important;
  margin: 0 auto !important;
  width: 25px !important;
}
