// TODO: borrar y actualizar las clases con tailwind

/* Width */
.w_screen {
  width: 100vh !important;
}
.w-full {
  width: 100% !important;
}
.le_w_95 {
  width: 95% !important;
}
.le_w_90 {
  width: 90% !important;
}
.le_w_85 {
  width: 85% !important;
}
.le_w_80 {
  width: 80% !important;
}
.le_w_75 {
  width: 75% !important;
}
.le_w_70 {
  width: 70% !important;
}
.le_w_65 {
  width: 65% !important;
}
.le_w_60 {
  width: 60% !important;
}
.le_w_50 {
  width: 50% !important;
}
.le_w_45 {
  width: 45% !important;
}
.le_w_40 {
  width: 40% !important;
}
.le_w_35 {
  width: 35% !important;
}
.le_w_30 {
  width: 30% !important;
}
.le_w_25 {
  width: 25% !important;
}
.le_w_20 {
  width: 20% !important;
}
.le_w_15 {
  width: 15% !important;
}
.le_w_10 {
  width: 10% !important;
}
/* Height */
.h-screen {
  height: 100vh !important;
}
.h-full {
  height: 100% !important;
}
.le_h_90 {
  height: 90% !important;
}
.le_h_80 {
  height: 80% !important;
}
.le_h_70 {
  height: 70% !important;
}
.le_h_60 {
  height: 60% !important;
}
.le_h_50 {
  height: 50% !important;
}
.le_h_40 {
  height: 40% !important;
}
.le_h_30 {
  height: 30% !important;
}
.le_h_20 {
  height: 20% !important;
}
.le_h_15 {
  height: 15% !important;
}
.le_h_10 {
  height: 10% !important;
}

/* Customs */
.w_220_px {
  width: 220px;
}
.w_200_px {
  width: 200px;
}
.w_160_px {
  width: 160px;
}
.w-100px {
  width: 100px;
}

.h-50px {
  height: 50px;
}
.h-60px {
  height: 60px;
}
.h-100px {
  height: 100px;
}
