@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./assets/scss/tailwind";

@import "./assets/scss/sweetalert2";

@import "./assets/scss/material-config";
@import "./assets/scss/material-elements";
@import "./assets/scss/scroll";
@import "./assets/scss/size";

@import "~@ng-select/ng-select/themes/default.theme.css";

html,
body {
  font-family: "Poppins", sans-serif, FontAwesome !important;
  height: 100%;
  margin: 0;
  overflow-y: hidden;
}

// TODO: MIGRAR A OTRO LADO
input:disabled,
textarea:disabled,
select:disabled {
  @apply bg-gray-100 border-2 border-gray-200 rounded-md w-full text-gray-700 placeholder-gray-700 leading-tight transition ease-in-out text-sm py-2 px-4 h-10 cursor-not-allowed;
}

.loader {
  margin-bottom: 4px !important;
  background: #dbdbdb;
  outline: none !important;
}

.resume-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
}

.color-red {
  color: rgb(253, 82, 82) !important;
}

.btn-opciones {
  width: 30px;
  text-align: center;
}

.bg-primary {
  background-color: #417cff;
}
.color-primary {
  color: #417cff;
}

.le_textarea_sm {
  height: 100px;
}
.le_textarea_md {
  height: 200px;
}
.le_textarea_lg {
  height: 300px;
}

.ng-select {
  height: 100% !important;

  .ng-select-container {
    border-radius: 0.375rem !important;
    border: 2px solid rgb(229 231 235) !important;
    height: 100% !important;
    padding-left: 5px;

    .ng-value-container {
      color: rgb(55, 65, 81) !important;
      .ng-placeholder {
        color: rgb(55, 65, 81) !important;
      }
    }
  }
}

.mat-paginator-range-actions {
  gap: 0.5rem;
}
